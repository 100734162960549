import { Auth } from 'aws-amplify';
import constants from '@lmig-latam/adil-api-common-lib/constants';
import { getApiEndpoint, isAsianDomain } from '../environmentsHelpers';
import clFlag from '../../../styles/images/country-flag-circle-images/cl.svg';
import coFlag from '../../../styles/images/country-flag-circle-images/co.svg';
import ecFlag from '../../../styles/images/country-flag-circle-images/ec.svg';
import vnFlag from '../../../styles/images/country-flag-circle-images/vn.svg';
import thFlag from '../../../styles/images/country-flag-circle-images/th.png';
import { manageAccountUrl, changePasswordUrl } from '../../../utils/constants';

const {
  LANGUAGES: { CL },
} = constants;

const {
  identityPoolId,
  region,
  userPoolId,
  userPoolWebClientId,
} = isAsianDomain()
  ? constants.AWS_CONFIG.dev.asia
  : constants.AWS_CONFIG.dev.latam;

const manageUrl = isAsianDomain()
  ? manageAccountUrl.DEV.asia
  : manageAccountUrl.DEV.latam;

const PasswordUrl = isAsianDomain()
  ? changePasswordUrl.DEV.asia
  : changePasswordUrl.DEV.latam;

export default {
  manageAccountUrl: manageUrl,
  changePasswordUrl: PasswordUrl,
  countryCode: CL,
  language: CL,
  enableCreateInspectionPrefill: true,
  displayImageAnalysis: true,
  cl: {
    vigiladoLogoDisplayed: false,
    countryFlag: clFlag,
  },
  co: {
    vigiladoLogoDisplayed: true,
    countryFlag: coFlag,
  },
  ec: {
    vigiladoLogoDisplayed: false,
    countryFlag: ecFlag,
  },
  vn: {
    vigiladoLogoDisplayed: false,
    countryFlag: vnFlag,
  },
  th: {
    vigiladoLogoDisplayed: false,
    countryFlag: thFlag,
  },

  // AWS Amplify Config
  awsConfig: {
    Auth: {
      // Amazon Cognito Identity Pool ID
      identityPoolId,
      // Amazon Cognito Region
      region,
      // Amazon Cognito User Pool ID
      userPoolId,
      // Amazon Cognito Web Client ID
      userPoolWebClientId,
      // Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,
      // oauth
      oauth: {
        domain: `adil-agent-dev.auth.${region}.amazoncognito.com`,
        scope: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'http://localhost:3000/app/',
        redirectSignOut:
          'https://hdi-dev-co.us.auth0.com/v2/logout?returnTo=http://localhost:3000/app/',
        responseType: 'code',
      },
    },
    Analytics: {
      disabled: true,
    },
    Storage: {
      AWSS3: {
        bucket: 'asiamcm-dev-adil-document-vn',
        region,
        identityPoolId,
      },
      level: 'private',
    },
    API: {
      endpoints: [
        {
          name: 'dev-adil-agent-apis',
          endpoint: getApiEndpoint(),
          region,
          custom_header: async () => ({
            jwttoken: (await Auth.currentSession()).getIdToken().getJwtToken(),
          }),
        },
      ],
    },
  },
};
